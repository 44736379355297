var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-row", { staticClass: "mt-2 mx-3 justify-content-end" }, [
        _c(
          "span",
          { staticClass: "updateRequest" },
          [
            _c("b-icon", {
              directives: [
                {
                  name: "b-popover",
                  rawName: "v-b-popover.hover.left",
                  value: "Update!",
                  expression: "'Update!'",
                  modifiers: { hover: true, left: true }
                }
              ],
              staticClass: "border rounded p-2",
              attrs: { icon: "bell" },
              on: { click: _vm.updateTableAuthPrograms }
            })
          ],
          1
        )
      ]),
      _vm.configRol.administrador.pestana.auth_programs.acciones.listar
        ? _c(
            "b-row",
            { staticClass: "mt-3 mx-3" },
            [
              _c(
                "b-col",
                { staticClass: "tableRequest" },
                [
                  _c("b-table", {
                    attrs: {
                      items: _vm.items,
                      fields: _vm.fields,
                      stacked: "md",
                      "show-empty": "",
                      borderless: "",
                      "tbody-tr-class": _vm.rowClass,
                      "thead-tr-class": "table-head-none",
                      busy: _vm.isBusy
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "cell(name)",
                          fn: function(row) {
                            return [
                              _c("h5", { staticClass: "ml-3" }, [
                                _vm._v(
                                  _vm._s(_vm.$t("admin.auth." + row.value))
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "table-busy",
                          fn: function() {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass: "text-center text-primary my-2"
                                },
                                [
                                  _c("b-spinner", {
                                    staticClass: "align-middle"
                                  }),
                                  _c("strong", [_vm._v("Loading...")])
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "cell(actions)",
                          fn: function(row) {
                            return [
                              _c(
                                "b-button",
                                {
                                  staticClass: "btn button-details",
                                  attrs: { size: "sm" },
                                  on: { click: row.toggleDetails }
                                },
                                [
                                  row.detailsShowing
                                    ? _c("b-icon", {
                                        attrs: {
                                          icon: "chevron-up",
                                          "aria-hidden": "true"
                                        }
                                      })
                                    : _c("b-icon", {
                                        attrs: {
                                          icon: "chevron-down",
                                          "aria-hidden": "true"
                                        }
                                      })
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "row-details",
                          fn: function(row) {
                            return [
                              !row.item.resultado.length
                                ? _c("p", { staticClass: "text-center" }, [
                                    _c("b", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            row.item.error ||
                                              _vm.$t("admin.auth.noData")
                                          )
                                      )
                                    ])
                                  ])
                                : _c("b-table", {
                                    ref: "selectableTable",
                                    staticClass: "table-details",
                                    attrs: {
                                      items: row.item.resultado,
                                      fields: _vm.fieldInt,
                                      responsive: "sm",
                                      "tbody-tr-class": "table-body-Auth",
                                      "thead-tr-class": "table-head-Auth",
                                      "sort-icon-left": "",
                                      "sort-by": _vm.sortBy,
                                      "sort-desc": _vm.sortDesc
                                    },
                                    on: {
                                      "update:sortBy": function($event) {
                                        _vm.sortBy = $event
                                      },
                                      "update:sort-by": function($event) {
                                        _vm.sortBy = $event
                                      },
                                      "update:sortDesc": function($event) {
                                        _vm.sortDesc = $event
                                      },
                                      "update:sort-desc": function($event) {
                                        _vm.sortDesc = $event
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "cell(fecha_solicitud)",
                                          fn: function(row) {
                                            return [
                                              !row.detailsShowing
                                                ? _c("h6", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.dateInfo(
                                                            row.item
                                                              .fecha_solicitud,
                                                            "DD/MM/YYYY"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          }
                                        },
                                        {
                                          key: "cell(perfil)",
                                          fn: function(row) {
                                            return [
                                              !row.detailsShowing
                                                ? _c("b", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          row.item.perfil.slice(
                                                            0,
                                                            20
                                                          )
                                                        ) +
                                                        "... "
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          }
                                        },
                                        {
                                          key: "cell(score)",
                                          fn: function(row) {
                                            return [
                                              !row.detailsShowing
                                                ? _c("b", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(row.item.score) +
                                                        " "
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          }
                                        },
                                        {
                                          key: "cell(paciente)",
                                          fn: function(row) {
                                            return [
                                              _c("b", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      row.item.paciente_apellido
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      row.item.paciente_nombre
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ]
                                          }
                                        },
                                        {
                                          key: "cell(autorizador)",
                                          fn: function(row) {
                                            return [
                                              _c("b", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      row.item.autorizador
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ]
                                          }
                                        },
                                        {
                                          key: "cell(programas_solicitados)",
                                          fn: function(row) {
                                            return [
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass: "button-details",
                                                  attrs: { size: "sm" },
                                                  on: {
                                                    click: row.toggleDetails
                                                  }
                                                },
                                                [
                                                  row.detailsShowing
                                                    ? _c("b-icon", {
                                                        attrs: {
                                                          icon: "chevron-up",
                                                          "aria-hidden": "true"
                                                        }
                                                      })
                                                    : _c("b-icon", {
                                                        attrs: {
                                                          icon: "chevron-down",
                                                          "aria-hidden": "true"
                                                        }
                                                      })
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        },
                                        {
                                          key: "row-details",
                                          fn: function(row) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "internal-info"
                                                },
                                                [
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.dateInfo(
                                                          row.item
                                                            .fecha_solicitud,
                                                          "DD/MM/YYYY h:mm:ss a"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass: "ml-3 mr-5"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(row.item.perfil)
                                                      )
                                                    ]
                                                  ),
                                                  _c("b-form-group", [
                                                    _c("b", [
                                                      _vm._v("Score Azul/verde")
                                                    ]),
                                                    _c("p", [
                                                      _vm._v(
                                                        _vm._s(
                                                          row.item.titulo_score
                                                        )
                                                      )
                                                    ]),
                                                    _c("b", [
                                                      _vm._v(
                                                        "Score socieconomico"
                                                      )
                                                    ]),
                                                    _c("p", [
                                                      _vm._v(
                                                        _vm._s(
                                                          row.item.socieconomico
                                                        )
                                                      )
                                                    ]),
                                                    _c("b", [
                                                      _vm._v("Sucursal")
                                                    ]),
                                                    _c("p", [
                                                      _vm._v(
                                                        _vm._s(
                                                          row.item.sucursal
                                                        )
                                                      )
                                                    ])
                                                  ]),
                                                  _c("b-form-group", {
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var ariaDescribedby =
                                                              ref.ariaDescribedby
                                                            return [
                                                              _c(
                                                                "label",
                                                                {
                                                                  attrs: {
                                                                    for:
                                                                      "checkbox-group-2"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "admin.auth.request"
                                                                      )
                                                                    ) + ":"
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "b-form-checkbox-group",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "checkbox-group-2",
                                                                    "aria-describedby": ariaDescribedby,
                                                                    name:
                                                                      "flavour-2"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.selected,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.selected = $$v
                                                                    },
                                                                    expression:
                                                                      "selected"
                                                                  }
                                                                },
                                                                [
                                                                  row.item
                                                                    .estado_autorización ===
                                                                  "Pendiente"
                                                                    ? _vm._l(
                                                                        row.item
                                                                          .programas_solicitados,
                                                                        function(
                                                                          item,
                                                                          index
                                                                        ) {
                                                                          return _c(
                                                                            "b-form-checkbox",
                                                                            {
                                                                              key: index,
                                                                              attrs: {
                                                                                value:
                                                                                  item +
                                                                                  "*" +
                                                                                  row
                                                                                    .item
                                                                                    ._id
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "b",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      item
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        }
                                                                      )
                                                                    : row.item
                                                                        .estado_autorización ===
                                                                      "Aprobada"
                                                                    ? _vm._l(
                                                                        row.item
                                                                          .programas_solicitados,
                                                                        function(
                                                                          item,
                                                                          index
                                                                        ) {
                                                                          return _c(
                                                                            "p",
                                                                            {
                                                                              key: index
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "b",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      item
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ]
                                                                          )
                                                                        }
                                                                      )
                                                                    : [
                                                                        _c(
                                                                          "b",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "admin.auth.none"
                                                                                )
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                ],
                                                                2
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  }),
                                                  _c(
                                                    "div",
                                                    { staticClass: "ml-4" },
                                                    [
                                                      _c("div", [
                                                        _c("h6", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "admin.auth.dataPatient"
                                                              )
                                                            ) + ":"
                                                          )
                                                        ]),
                                                        row.item
                                                          .estado_autorización ===
                                                        "Pendiente"
                                                          ? _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "ml-2"
                                                              },
                                                              [
                                                                _c("b", [
                                                                  _vm._v(
                                                                    "NHC: "
                                                                  )
                                                                ]),
                                                                row.item
                                                                  .negocio ==
                                                                "México"
                                                                  ? _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            row
                                                                              .item
                                                                              .nhc_mex
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            row
                                                                              .item
                                                                              .nhc_usa
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                              ]
                                                            )
                                                          : _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "ml-2"
                                                              },
                                                              [
                                                                _c("b", [
                                                                  _vm._v(
                                                                    "NHC: "
                                                                  )
                                                                ]),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      row.item
                                                                        .paciente_nhc
                                                                    ) +
                                                                    " "
                                                                )
                                                              ]
                                                            ),
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass: "ml-2"
                                                          },
                                                          [
                                                            _c("b", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "admin.auth.name"
                                                                  )
                                                                ) + ": "
                                                              )
                                                            ]),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  row.item
                                                                    .paciente_apellido
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  row.item
                                                                    .paciente_nombre
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass: "ml-2"
                                                          },
                                                          [
                                                            _c("b", [
                                                              _vm._v("Email: ")
                                                            ]),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  row.item
                                                                    .paciente
                                                                ) +
                                                                " "
                                                            )
                                                          ]
                                                        )
                                                      ]),
                                                      _c(
                                                        "div",
                                                        { staticClass: "mt-3" },
                                                        [
                                                          _c("h6", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "admin.auth.applicant"
                                                                )
                                                              ) + ":"
                                                            )
                                                          ]),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "ml-2"
                                                            },
                                                            [
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "admin.auth.name"
                                                                    )
                                                                  ) + ": "
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    row.item
                                                                      .nombre_solicitante
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "ml-2"
                                                            },
                                                            [
                                                              _c("b", [
                                                                _vm._v(
                                                                  "Email: "
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    row.item
                                                                      .solicitante
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "p",
                                                        { staticClass: "mt-3" },
                                                        [
                                                          _c("b", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "admin.auth.justification"
                                                                )
                                                              ) + ": "
                                                            )
                                                          ]),
                                                          _vm._v(
                                                            _vm._s(
                                                              row.item
                                                                .motivo_solicitante
                                                            ) + " "
                                                          )
                                                        ]
                                                      ),
                                                      row.item
                                                        .estado_autorización ===
                                                      "Rechazado"
                                                        ? _c(
                                                            "p",
                                                            {
                                                              staticClass:
                                                                "mt-4"
                                                            },
                                                            [
                                                              _c("b", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "admin.auth.disapproval"
                                                                    )
                                                                  ) + ": "
                                                                )
                                                              ]),
                                                              _vm._v(
                                                                _vm._s(
                                                                  row.item
                                                                    .motivo_autoriza_no_autoriza
                                                                ) + " "
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              row.item.estado_autorización ===
                                              "Pendiente"
                                                ? _c(
                                                    "b-row",
                                                    {
                                                      staticClass:
                                                        "group-button justify-content-end mr-4 my-3"
                                                    },
                                                    [
                                                      _vm.configRol
                                                        .administrador.pestana
                                                        .auth_programs.acciones
                                                        .rechazar
                                                        ? _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "button ml-0",
                                                              attrs: {
                                                                variant:
                                                                  "outline-secondary",
                                                                pill: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.cancelApprove(
                                                                    row.item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("b-icon", {
                                                                staticClass:
                                                                  "icon",
                                                                attrs: {
                                                                  icon: "x",
                                                                  "aria-hidden":
                                                                    "true"
                                                                }
                                                              }),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "admin.auth.notApprove"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.configRol
                                                        .administrador.pestana
                                                        .auth_programs.acciones
                                                        .aceptar
                                                        ? _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "button ml-3",
                                                              attrs: {
                                                                variant:
                                                                  "success",
                                                                pill: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.successApprove(
                                                                    row.item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("b-icon", {
                                                                staticClass:
                                                                  "icon",
                                                                attrs: {
                                                                  icon:
                                                                    "check2",
                                                                  "aria-hidden":
                                                                    "true"
                                                                }
                                                              }),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "admin.auth.approve"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      28391836
                    )
                  })
                ],
                1
              )
            ],
            1
          )
        : _c(
            "b-row",
            { staticClass: "mt-3 mx-3" },
            [
              _c("b-alert", { attrs: { show: "", dismissible: "" } }, [
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("permissions.msgPermissionsAuth")))
                ])
              ])
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }