<template>
  <div>
    <b-row class="mt-2 mx-3 justify-content-end">
      <span class="updateRequest">
        <b-icon
          v-b-popover.hover.left="'Update!'"
          icon="bell"
          class="border rounded p-2"
          @click="updateTableAuthPrograms"
        ></b-icon>
      </span>
    </b-row>
    <b-row class="mt-3 mx-3" v-if="configRol.administrador.pestana.auth_programs.acciones.listar">
      <b-col class="tableRequest">
        <!-- Main table element -->
        <b-table
          :items="items"
          :fields="fields"
          stacked="md"
          show-empty
          borderless
          :tbody-tr-class="rowClass"
          thead-tr-class="table-head-none"
          :busy="isBusy"
          
        >
          <template #cell(name)="row">
            <h5 class="ml-3">{{ $t(`admin.auth.${row.value}`) }}</h5>
          </template>

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(actions)="row">
            <b-button
              size="sm"
              class="btn button-details"
              @click="row.toggleDetails"
            >
              <b-icon
                icon="chevron-up"
                aria-hidden="true"
                v-if="row.detailsShowing"
              ></b-icon>
              <b-icon icon="chevron-down" aria-hidden="true" v-else></b-icon>
            </b-button>
          </template>

          <template #row-details="row">
            <p v-if="!row.item.resultado.length" class="text-center">
              <b> {{ row.item.error || $t(`admin.auth.noData`) }}</b>
            </p>
            <b-table
              :items="row.item.resultado"
              :fields="fieldInt"
              responsive="sm"
              ref="selectableTable"
              tbody-tr-class="table-body-Auth"
              thead-tr-class="table-head-Auth"
              class="table-details"
              sort-icon-left
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              v-else
            >
              <template #cell(fecha_solicitud)="row">
                <h6 v-if="!row.detailsShowing">
                  {{ dateInfo(row.item.fecha_solicitud, "DD/MM/YYYY") }}
                </h6>
              </template>
              <template #cell(perfil)="row">
                <b v-if="!row.detailsShowing">
                  {{ row.item.perfil.slice(0, 20) }}...
                </b>
              </template>
              <template #cell(score)="row">
                <b v-if="!row.detailsShowing">
                  {{ row.item.score }}
                </b>
              </template>
              <template #cell(paciente)="row">
                <b>
                  {{ row.item.paciente_apellido }}
                  {{ row.item.paciente_nombre }}
                </b>
              </template>
              <template #cell(autorizador)="row">
                <b>
                  {{ row.item.autorizador }}
                </b>
              </template>

              <template #cell(programas_solicitados)="row">
                <b-button
                  size="sm"
                  class="button-details"
                  @click="row.toggleDetails"
                >
                  <b-icon
                    icon="chevron-up"
                    aria-hidden="true"
                    v-if="row.detailsShowing"
                  ></b-icon>
                  <b-icon
                    icon="chevron-down"
                    aria-hidden="true"
                    v-else
                  ></b-icon>
                </b-button>
              </template>

              <template #row-details="row">
                <div class="internal-info">
                  <b>{{
                    dateInfo(row.item.fecha_solicitud, "DD/MM/YYYY  h:mm:ss a")
                  }}</b>
                  <p class="ml-3 mr-5">{{ row.item.perfil }}</p>
                  <b-form-group >
                    <b>Score Azul/verde</b>
                  <p>{{ row.item.titulo_score }}</p>
                  <b>Score socieconomico</b>
                  <p>{{ row.item.socieconomico }}</p>
                  <b>Sucursal</b>
                  <p>{{ row.item.sucursal }}</p>
                  
                  </b-form-group>
                  <b-form-group v-slot="{ ariaDescribedby }">
                    <label for="checkbox-group-2"
                      >{{ $t("admin.auth.request") }}:</label
                    >
                    <b-form-checkbox-group
                      id="checkbox-group-2"
                      v-model="selected"
                      :aria-describedby="ariaDescribedby"
                      name="flavour-2"
                    >
                      <template
                        v-if="row.item.estado_autorización === 'Pendiente'"
                      >
                        <b-form-checkbox
                          v-for="(item, index) in row.item
                            .programas_solicitados"
                          :key="index"
                          :value="`${item}*${row.item._id}`"
                          ><b>{{ item }}</b></b-form-checkbox
                        >
                      </template>
                      <template
                        v-else-if="row.item.estado_autorización === 'Aprobada'"
                      >
                        <p
                          v-for="(item, index) in row.item
                            .programas_solicitados"
                          :key="index"
                        >
                          <b>{{ item }}</b>
                        </p>
                      </template>
                      <template v-else>
                        <b>{{ $t("admin.auth.none") }}</b>
                      </template>
                    </b-form-checkbox-group>
                  </b-form-group>
                  <div class="ml-4">
                    <div>
                      <h6>{{ $t(`admin.auth.dataPatient`) }}:</h6>
                      <p class="ml-2" v-if="row.item.estado_autorización === 'Pendiente'">
                        <b>NHC: </b> 
                        <label v-if="row.item.negocio == 'México'">{{row.item.nhc_mex}}</label>
                        <label v-else>{{row.item.nhc_usa}}</label>
                      <p class="ml-2" v-else>
                        <b>NHC: </b> {{ row.item.paciente_nhc }}
                      </p>
                      <p class="ml-2">
                        <b>{{ $t(`admin.auth.name`) }}: </b>
                        {{ row.item.paciente_apellido }}
                        {{ row.item.paciente_nombre }}
                      </p>
                      <p class="ml-2">
                        <b>Email: </b>
                        {{ row.item.paciente }}
                      </p>
                    </div>
                    <div class="mt-3">
                      <h6>{{ $t(`admin.auth.applicant`) }}:</h6>
                      <p class="ml-2">
                        <b>{{ $t(`admin.auth.name`) }}: </b>
                        {{ row.item.nombre_solicitante }}
                      </p>
                      <p class="ml-2">
                        <b>Email: </b>
                        {{ row.item.solicitante }}
                      </p>
                    </div>
                    <p class="mt-3">
                      <b>{{ $t("admin.auth.justification") }}: </b
                      >{{ row.item.motivo_solicitante }}
                    </p>
                    <p
                      class="mt-4"
                      v-if="row.item.estado_autorización === 'Rechazado'"
                    >
                      <b>{{ $t("admin.auth.disapproval") }}: </b
                      >{{ row.item.motivo_autoriza_no_autoriza }}
                    </p>
                  </div>
                </div>
                <b-row
                  class="group-button justify-content-end mr-4 my-3"
                  v-if="row.item.estado_autorización === 'Pendiente'"
                >
                  <b-button
                    variant="outline-secondary"
                    class="button ml-0"
                    pill
                    @click="cancelApprove(row.item)"
                     v-if="configRol.administrador.pestana.auth_programs.acciones.rechazar"
                  >
                    <b-icon icon="x" class="icon" aria-hidden="true"></b-icon>
                    {{ $t("admin.auth.notApprove") }}
                  </b-button>
                  <b-button
                    variant="success"
                    class="button ml-3"
                    pill
                    @click="successApprove(row.item)"
                     v-if="configRol.administrador.pestana.auth_programs.acciones.aceptar"
                  >
                    <b-icon
                      icon="check2"
                      class="icon"
                      aria-hidden="true"
                    ></b-icon>
                    {{ $t("admin.auth.approve") }}
                  </b-button>
                </b-row>
              </template>
            </b-table>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row class="mt-3 mx-3" v-else>
      <b-alert show dismissible>
          <p>{{ $t("permissions.msgPermissionsAuth") }}</p>
        </b-alert>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import { API_ROOT, API_HUBSPOT } from "../../Constants/Api";
import Swal from "sweetalert2";
import { notifications } from "../../helpers/notifications";
import { AlertForm, ICON } from '../../helpers/Alert';
import { ApiRequest } from '../../helpers/ApiRequest';

export default {
  name: "Auth",
  components: {},
  data: () => ({
    fields: [
      { key: "name", label: "Name", sortable: true, sortDirection: "desc" },
      { key: "actions", label: "" },
    ],
    items: [],
    selected: [],
    isBusy: false,
    sortDesc: true,
    sortBy: "fecha_solicitud",
    configRol: {}

  }),
  created() {
    this.configRol = this.$store.state.configRol;
    if(this.configRol.administrador?.pestana.auth_programs.permiso === false) {
      this.$router.push({ name: "Welcome" });
       AlertForm(`${this.$i18n.t("permissions.unauthorized")} ${this.$i18n.t("admin.auth.title")}` , this.$i18n.t("permissions.validateAdmin"), ICON.WARNING, "center", null);
    }
  },
  watch: {
    async count_pendings() {
      try {
        await this.updateTableAuthPrograms();
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    count_pendings() {
      return this.$store.state.count_pending.sol_programas;
    },
    fieldInt() {
      return [
        {
          key: "fecha_solicitud",
          label: this.$i18n.t("admin.auth.date"),

          sortDirection: "desc",
        },
        {
          key: "perfil",
          label: this.$i18n.t("admin.auth.profile"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "score",
          label: this.$i18n.t("admin.auth.score"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "programas_solicitados",
          label: this.$i18n.t("admin.auth.request"),
        },
        {
          key: "autorizador",
          label: this.$i18n.t("admin.auth.authorized"),
          sortable: true,
        },
        {
          key: "paciente",
          label: this.$i18n.t("admin.auth.patient"),
          sortable: true,
          sortDirection: "desc",
        },
      ];
    },
  },

  async mounted() {
    try {
      await this.updateTableAuthPrograms();
    } catch (err) {
      console.log(err);
    }
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.isActive) return "table-row-title-action";
    },
    dateInfo(date, format) {
      return moment(date.slice(0, -1)).format(format);
    },
    async cancelApprove(item) {
      const { value: text } = await Swal.fire({
        input: "textarea",
        confirmButtonColor: "#325dab",
        cancelButtonColor: "#c1c1c1",
        confirmButtonText: "Send",

        title: this.$i18n.t("admin.auth.titleDisapproval"),
        text: this.$i18n.t("admin.auth.messageDisapproval"),
        inputPlaceholder: this.$i18n.t("admin.auth.placeholder"),
        showCancelButton: false,
      });

      if (text) {
        this.apiRequestProgram(0, item, text);
        notifications();
      }
    },
    successApprove(item) {
      this.apiRequestProgram(1, item);
    },

    async updateTableAuthPrograms() {
      
        let infoPending, infoApproved, infoNotApproved;
      try {
        this.items = [];
        this.isBusy = true;
        const pending = await ApiRequest.get(`${API_ROOT}solicitudesProgramas/estado/Pendiente`);
        let datos = [];
        for (var i = 0; i < pending.datos.length; i++) {
          const data = await ApiRequest.post(`${API_HUBSPOT}getInternational`, {
            email: pending.datos[i].paciente
          });
          
          let score = (data.body.properties.score_azul_verde != undefined ? data.body.properties.score_azul_verde.value : "");
          let socieconomico = (data.body.properties.score_socioeconomico_mx != undefined? data.body.properties.score_socioeconomico_mx.value : "");
          let negocio = (data.body.properties.unidad_de_negocio != undefined ? data.body.properties.unidad_de_negocio.value :"");
          
          var newData = {};
          
          if(data.body.properties.unidad_de_negocio.value === "México"){
            newData = {
              edad: pending.datos[i].edad,
              estado_autorización: pending.datos[i].estado_autorización,
              fecha_solicitud: pending.datos[i].fecha_solicitud,
              motivo_solicitante: pending.datos[i].motivo_solicitante,
              nombre_solicitante: pending.datos[i].nombre_solicitante,
              paciente: pending.datos[i].paciente,
              paciente_apellido: pending.datos[i].paciente_apellido,
              paciente_nhc: pending.datos[i].paciente_nhc,
              paciente_nombre: pending.datos[i].paciente_nombre,
              perfil: pending.datos[i].perfil,
              programas_solicitados: pending.datos[i].programas_solicitados,
              score: pending.datos[i].score,
              solicitante: pending.datos[i].solicitante,
              _id: pending.datos[i]._id,
              titulo_score : score,
              sucursal : (data.body.properties.sucursal_mexico != undefined? data.body.properties.sucursal_mexico.value : "") ,
              socieconomico : socieconomico,
              nhc_mex : (data.body.properties.nhc_del_paciente_definitivo_mx != undefined? data.body.properties.nhc_del_paciente_definitivo_mx.value : "") ,
              nhc_usa: "",
              negocio: negocio
            };
          }else{
            newData = {
              edad: pending.datos[i].edad,
              estado_autorización: pending.datos[i].estado_autorización,
              fecha_solicitud: pending.datos[i].fecha_solicitud,
              motivo_solicitante: pending.datos[i].motivo_solicitante,
              nombre_solicitante: pending.datos[i].nombre_solicitante,
              paciente: pending.datos[i].paciente,
              paciente_apellido: pending.datos[i].paciente_apellido,
              paciente_nhc: pending.datos[i].paciente_nhc,
              paciente_nombre: pending.datos[i].paciente_nombre,
              perfil: pending.datos[i].perfil,
              programas_solicitados: pending.datos[i].programas_solicitados,
              score: pending.datos[i].score,
              solicitante: pending.datos[i].solicitante,
              _id: pending.datos[i]._id,
              titulo_score : score,
              sucursal : (data.body.properties.sucursal != undefined ? data.body.properties.sucursal.value : ""),
              socieconomico : socieconomico,
              nhc_mex : "",
              nhc_usa: (data.body.properties.id_meditex != undefined ? data.body.properties.id_meditex.value : ""),
              negocio: negocio
            };
          }
          datos.push(newData)
        }
        notifications();
        infoPending = {
          isActive: true,
          name: "pending",
          _showDetails: true,
          resultado: datos || [],
          error: ""
        };
        
      } catch (err) {
        infoPending = {
            isActive: true,
            name: "pending",
            _showDetails: true,
            resultado: [],
            error: this.$i18n.t("admin.auth.errAuthPending")
          };
        if(err.status !== "Data Failure") {
          AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
        } else {
           infoPending.error= ""
        }
        
      }
      try {
        const approved = await ApiRequest.get(`${API_ROOT}solicitudesProgramas/estado/Aprobada`);
        infoApproved = {
          isActive: true,
          name: "approved",
          resultado: approved.datos || [],
           error: ""
        };
      } catch (err) {
        infoApproved = {
            isActive: true,
            name: "approved",
            resultado: [],
            error: this.$i18n.t("admin.auth.errAuthApprove")
          };
        if(err.status !== "Data Failure") {
          AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
        } else {
           infoApproved.error= ""
        }
          
  
      }

      try {
        const notApproved = await ApiRequest.get(`${API_ROOT}solicitudesProgramas/estado/Rechazado`);
        infoNotApproved = {
          isActive: true,
          name: "unauthorized",
          resultado: notApproved.datos || [],
           error: ""
        };
      } catch (err) {
        infoNotApproved = {
            isActive: true,
            name: "unauthorized",
            resultado: [],
            error: this.$i18n.t("admin.auth.errAuthNotApproved")
          };
         if(err.status !== "Data Failure") {
          AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
         } else {
           infoNotApproved.error= ""
        }
           
        } finally {
          this.isBusy = false;
          }
        this.items = [
            { ...infoPending },
            { ...infoApproved },
            { ...infoNotApproved },
        ];

    },
    async apiRequestProgram(status, item, text = "") {
      try {
        const selectedPrograms = this.selected.filter((el) =>
          el.includes(item._id)
        );
        if (status && !selectedPrograms.length) {
          AlertForm(this.$i18n.t("admin.auth.notSelected"), "", ICON.ERROR);
          return;
        }
        await ApiRequest.put(`${API_ROOT}solicitudesProgramas`, {
          id: item._id,
          correo_autoriza: this.$store.state.userResponse.email,
          estado: status,
          justificacion_admin: text,
          programas_autorizados: selectedPrograms.map((el) => el.split("*")[0]),
        });
        this.selected = this.selected.filter((el) => !el.includes(item._id));
        await this.updateTableAuthPrograms();
        AlertForm(this.$i18n.t("admin.auth.submit"), "", ICON.SUCCESS);
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t("permissions.errorUpdateRequestPrograms"), ICON.ERROR);
      }
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/global.css";

.internal-info {
  display: grid;
  grid-template-columns: 0.8fr 1.8fr 0.7fr 1fr 2.5fr;
  padding: 0.8rem;
  margin-bottom: 3rem;
}

.group-button .button {
  min-width: 150px;
}

/*** detalle boton detalles */
.button-details {
  background: transparent;
  color: black;
  border: none;
}

.updateRequest {
  font-size: 3rem;
}
.updateRequest > svg {
  cursor: pointer;
}

/**TABLE */

.tableRequest /deep/ .table-head-none {
  display: none;
}
</style>
