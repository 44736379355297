<template>
  <div>
    <b-row class="mt-2 mx-3 justify-content-end">
      <span class="updateRequest">
        <b-icon
          v-b-popover.hover.left="'Update!'"
          icon="bell"
          class="border rounded p-2"
          @click="updateTableAuthDiscounts"
        ></b-icon>
      </span>
    </b-row>
    <b-row class="mt-3 mx-3" v-if="configRol.administrador.pestana.auth_discounts.acciones.listar">
      <b-col class="tableRequest">
        <!-- Main table element -->
        <b-table
          :items="items"
          :fields="fields"
          stacked="md"
          show-empty
          borderless
          :tbody-tr-class="rowClass"
          thead-tr-class="table-head-none"
          :busy="isBusy"
          
        >
          <template #cell(name)="row">
            <h5 class="ml-3">{{ $t(`admin.authDiscounts.${row.value}`) }}</h5>
          </template>

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(actions)="row">
            <b-button
              size="sm"
              class="btn button-details"
              @click="row.toggleDetails"
            >
              <b-icon
                icon="chevron-up"
                aria-hidden="true"
                v-if="row.detailsShowing"
              ></b-icon>
              <b-icon icon="chevron-down" aria-hidden="true" v-else></b-icon>
            </b-button>
          </template>

          <template #row-details="row">
            <p v-if="!row.item.resultado.length" class="text-center">
              <b> {{ row.item.error || $t(`admin.auth.noData`) }}</b>
            </p>
            <b-table
              :items="row.item.resultado"
              :fields="fieldInt"
              responsive="sm"
              ref="selectableTable"
              tbody-tr-class="table-body-Auth"
              thead-tr-class="table-head-Auth"
              class="table-details"
              sort-icon-left
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              v-else
            >
            <template #cell(product)="row">
                <h6 v-if="row.item.producto">
                  {{ row.item.producto }}
                </h6>
              </template>
              <template #cell(fecha_solicita)="row">
                <h6 v-if="row.item.fecha">
                  {{ dateInfo(row.item.fecha, "DD/MM/YYYY") }}
                </h6>
              </template>
              
              <template #cell(show_detail)="row">
                <b-button
                  size="sm"
                  class="button-details"
                  @click="row.toggleDetails"
                >
                  <b-icon
                    icon="chevron-up"
                    aria-hidden="true"
                    v-if="row.detailsShowing"
                  ></b-icon>
                  <b-icon
                    icon="chevron-down"
                    aria-hidden="true"
                    v-else
                  ></b-icon>
                </b-button>
              </template>

              <template #row-details="row">
                <div class="internal-info">
                  <b>{{
                    dateInfo(row.item.fecha, "DD/MM/YYYY  h:mm:ss a")
                  }}</b>
                  <div class="ml-3">
                    <div>
                      <p class="ml-2">
                        <b>{{ $t(`admin.authDiscounts.product`) }}: </b>{{row.item.producto}} 
                      </p>
                    </div>
                  </div>
                  <div class="ml-3">
                    <div>
                      <p class="ml-2">
                        <b>{{ $t(`admin.authDiscounts.precio_descuento`) }}: </b> 
                      </p>
                      {{ row.item.precio_descuento }}
                    </div>
                  </div>
                  
                  <div class="ml-3">
                    <div>
                      <p class="ml-2" v-if="row.item.estado === 'aprobado'">
                        <b>{{ $t(`admin.authDiscounts.usr_aprueba`) }}: </b>
                      </p>
                      <p class="ml-2" v-if="row.item.estado === 'rechazado'">
                        <b>{{ $t(`admin.authDiscounts.usr_desaprobo`) }}:</b>
                      </p>
                      <p  v-if="row.item.estado === 'pendiente'">--</p>
                      <p v-else-if="row.item.estado === 'aprobado' || row.item.estado === 'rechazado'">{{row.item.usr_aprueba}}</p>
                    </div>
                  </div>
                  <div class="ml-3">
                    <div>
                      <p class="ml-2">
                        <b>{{ $t(`admin.authDiscounts.porcentaje_descuento`) }}: </b>
                      </p>
                    </div>
                    <div v-if="row.item.estado === 'pendiente'">

                        <b-form-group>
                            <label for="checkbox-group-2">{{$t(`admin.authDiscounts.change_unit`)}}</label>
                            <b-form-checkbox 
                            v-model="row.item.unidad_medida"
                            @change="calcDiscount(row.item)"
                            ></b-form-checkbox>
                              
                            <template v-if="row.item.unidad_medida === false || row.item.unidad_medida === '%'">
                                <b-input-group class="mr-2 quantity-group" size="sm" append="%">
                                    <b-form-input
                                    type="number"
                                    class="quantity"
                                    min="0"
                                    max="100"
                                    v-model="row.item.descuento"
                                    @change="calcDiscount(row.item)"
                                    >
                                    </b-form-input>
                                </b-input-group>  
                            </template>
                            <template v-else-if="row.item.unidad_medida === true || row.item.unidad_medida === '$'">
                                <b-input-group class="mr-2 quantity-group" size="sm" append="$">
                                    <b-form-input
                                    type="number"
                                    class="quantity"
                                    min="0"
                                    :max="row.item.precio_lista"
                                    v-model="row.item.descuento"
                                    @change="calcDiscount(row.item)"
                                    >
                                    </b-form-input>
                                </b-input-group>
                            </template>
                        </b-form-group> 
                    </div>
                    <div v-else-if="row.item.estado === 'aprobado' || row.item.estado === 'rechazado'">
                        {{row.item.descuento}}
                    </div>
                  </div>
                </div>
                <div class="internal-info">
                  
                  <div class="ml-1">
                    <b>{{$t(`admin.authDiscounts.data_pacient`)}}</b>
                  
                    <p  class="ml-1">
                      <b>{{$t(`admin.authDiscounts.name_pacient`)}} </b>{{row.item.nombre}} 
                    </p>
                    <p class="ml-1">
                      <b>{{$t(`admin.authDiscounts.nhc`)}} </b>{{row.item.nhc}} 
                    </p>
                  </div>
                  <div class="ml-5">
                    <b class="ml-5">{{$t(`admin.authDiscounts.data_score`)}}</b>
                    <p class="ml-5">
                      <b>{{$t(`admin.authDiscounts.score`)}} </b>{{row.item.scoreAzulVerde}}
                    </p>
                    <p class="ml-5">
                      <b>{{$t(`admin.authDiscounts.score_mx`)}} </b>{{row.item.scoreEconomico}} 
                    </p>
                    <p class="ml-5">
                      <b>{{$t(`admin.authDiscounts.need_pacient`)}} </b>{{row.item.hs_buyer}} 
                    </p>
                  </div>
                  <div class="ml-1">
                    <p><b>{{$t(`admin.authDiscounts.date_first`)}} </b>  {{dateInfo(row.item.date_first_appoiment, "DD/MM/YYYY")}}</p>
                  </div>

                </div>
                <b-row
                  class="group-button justify-content-end mr-4 my-3"
                  v-if="row.item.estado === 'pendiente'"
                >
                  <b-button
                    variant="outline-secondary"
                    class="button ml-0"
                    pill
                    @click="cancelApprove(row.item)"
                     v-if="configRol.administrador.pestana.auth_discounts.acciones.rechazar"
                  >
                    <b-icon icon="x" class="icon" aria-hidden="true"></b-icon>
                    {{ $t("admin.auth.notApprove") }}
                  </b-button>
                  <b-button
                    variant="success"
                    class="button ml-3"
                    pill
                    @click="successApprove(row.item)"
                     v-if="configRol.administrador.pestana.auth_discounts.acciones.aceptar"
                  >
                    <b-icon
                      icon="check2"
                      class="icon"
                      aria-hidden="true"
                    ></b-icon>
                    {{ $t("admin.auth.approve") }}
                  </b-button>
                </b-row>
              </template>
            </b-table>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row class="mt-3 mx-3" v-else>
      <b-alert show dismissible>
          <p>{{ $t("permissions.msgPermissionsAuth") }}</p>
        </b-alert>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import { API_ROOT } from "../../Constants/Api";
import { notifications } from "../../helpers/notifications";
import { AlertForm, ICON } from '../../helpers/Alert';
import { ApiRequest } from '../../helpers/ApiRequest';

export default {
  name: "Auth",
  components: {},
  data: () => ({
    fields: [
      { key: "name", label: "Name", sortable: true, sortDirection: "desc" },
      { key: "actions", label: "" },
    ],
    items: [],
    selected: [],
    porcentajeInput: 0,
    isBusy: false,
    sortDesc: true,

    sortBy: "fecha_solicitud",
    configRol: {},
  }),
  created() {
    this.configRol = this.$store.state.configRol;
    if(this.configRol.administrador?.pestana.auth_discounts.permiso === false) {
      this.$router.push({ name: "Welcome" });
       AlertForm(`${this.$i18n.t("permissions.unauthorized")} ${this.$i18n.t("admin.auth.title")}` , this.$i18n.t("permissions.validateAdmin"), ICON.WARNING, "center", null);
    }
  },
  watch: {
    async count_pendings() {
      try {
        await this.updateTableAuthDiscounts();
      } catch (error) {
        console.log(error);
      }
    }
  },
  computed: {
    count_pendings() {
      return this.$store.state.count_pending.sol_descuentos;
    },
    fieldInt() {
      return [
        {
          key: "show_detail",
          label: this.$i18n.t("admin.authDiscounts.show_detail"),
        },
        {
          key: "product",
          label: this.$i18n.t("admin.authDiscounts.product"),
        },
        {
          key: "precio_lista",
          label: this.$i18n.t("admin.authDiscounts.precio_lista"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "porcentaje_descuento",
          label: this.$i18n.t("admin.authDiscounts.porcentaje_descuento"),
        },
        {
          key: "name_solicita",
          label: this.$i18n.t("admin.authDiscounts.usr_solicita"),
          sortable: true,
        },
        {
          key: "fecha_solicita",
          label: this.$i18n.t("admin.authDiscounts.fecha_solicita"),
          sortable: true,
        }
      ];
    },
  },

  async mounted() {
    try {
      await this.updateTableAuthDiscounts();
    } catch (err) {
        console.log(err);
    }
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.isActive) return "table-row-title-action";
    },
    calcDiscount(item ){
        if(item.unidad_medida === false || item.unidad_medida === '%'){
            item.precio_descuento = item.precio_lista - ((item.precio_lista * item.descuento ) / 100 ) ;
        }else{
            item.precio_descuento = item.precio_lista - item.descuento ;
        }
    },
    dateInfo(date, format) {
      return moment(date.slice(0, -1)).format(format);
    },
    async cancelApprove(item) {
        this.apiRequestProgram(0, item);
        notifications();
    },
    async successApprove(item) {
      this.apiRequestProgram(1, item);
    },

    async updateTableAuthDiscounts() {
      let infoPending, infoApproved, infoNotApproved;
      try {
        this.items = [];
        this.isBusy = true;
        const pending = await ApiRequest.get(`${API_ROOT}descuentosAutorizacion/estado/pendiente`);
        const result = pending.lista.filter(
            obj => (obj != null));
        notifications();
        infoPending = {
          isActive: true,
          name: "pending",
          _showDetails: true,
          resultado: result || [],
          error: ""
        };
      } catch (err) {
        infoPending = {
            isActive: true,
            name: "pending",
            _showDetails: true,
            resultado: [],
            error: this.$i18n.t("admin.auth.errAuthPending")
          };
        if(err.status !== "Data Failure") {
          AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
        } else {
           infoPending.error= ""
        }
        
      }
      try {
        const approved = await ApiRequest.get(`${API_ROOT}descuentosAutorizacion/estado/aprobado`);
        //console.log(approved.lista);
        infoApproved = {
          isActive: true,
          name: "approved",
          resultado: approved.lista || [],
          error: ""
        };
      } catch (err) {
        infoApproved = {
            isActive: true,
            name: "approved",
            resultado: [],
            error: this.$i18n.t("admin.auth.errAuthApprove")
          };
        if(err.status !== "Data Failure") {
          AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
        } else {
           infoApproved.error= ""
        }
          
  
      }

      try {
        const notApproved = await ApiRequest.get(`${API_ROOT}descuentosAutorizacion/estado/rechazado`);
        //console.log(notApproved.lista);
        infoNotApproved = {
          isActive: true,
          name: "unauthorized",
          resultado: notApproved.lista || [],
           error: ""
        };
      } catch (err) {
        infoNotApproved = {
            isActive: true,
            name: "unauthorized",
            resultado: [],
            error: this.$i18n.t("admin.auth.errAuthNotApproved")
        };
        if(err.status !== "Data Failure") {
          AlertForm(`Error Code: ${err.status}`, err.statusText, ICON.ERROR);
        } else {
          infoNotApproved.error= ""
        }
           
      } finally {
        this.isBusy = false;
      }
      this.items = [
          { ...infoPending },
          { ...infoApproved },
          { ...infoNotApproved },
      ];

    },
    async apiRequestProgram(status, item) {
      try {
        let unidad_medida = "";
        let statusText = "";
        if(status == 0){
            statusText = "rechazado";
        }else{
            statusText = "aprobado";
        }

        if(item.unidad_medida == true || item.unidad_medida == "$"){
            unidad_medida = "$";
        }else{
            unidad_medida = "%";
        }
        await ApiRequest.put(`${API_ROOT}descuentosAutorizacion/`, {
          id: item._id,
          producto: item.producto,
          precio_lista:item.precio_lista,
          precio_descuento:item.precio_descuento,
          descuento:item.descuento,
          unidad_medida: unidad_medida,
          usr_aprueba: this.$store.state.userResponse.email,
          usr_solicita: item.usr_solicita,
          fecha: item.fecha,
          estado: statusText
        });
        await this.updateTableAuthDiscounts();
        AlertForm(this.$i18n.t("admin.auth.submit"), "", ICON.SUCCESS);
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t("permissions.errorUpdateRequestPrograms"), ICON.ERROR);
      }
    },
  },
}
</script>

<style scoped>
@import "../../assets/css/global.css";

.internal-info {
  display: grid;
  grid-template-columns: 0.8fr 1.8fr 0.7fr 1fr 2.5fr;
  padding: 0.8rem;
}

.group-button .button {
  min-width: 150px;
}

/*** detalle boton detalles */
.button-details {
  background: transparent;
  color: black;
  border: none;
}

.updateRequest {
  font-size: 3rem;
}
.updateRequest > svg {
  cursor: pointer;
}

/**TABLE */

.tableRequest /deep/ .table-head-none {
  display: none;
}
</style>